export default {
    name: "BaseProspectus",
    created() {
        this.$nextTick(function () {
            // 获得数据
            this.GetDeployedBaseProspectus()
        });
    },
    mounted() {
        this.$nextTick(function () {
            // this.$$.navbarAnimation()
        })
    },
    data() {
        return {
            dataArr: []
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获得数据
        GetDeployedBaseProspectus() {
            let arr
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$$.mibb + "GetDeployedBaseProspectus",
                data: { token: "webkey" },
                success: (data) => {
                    if (data.status == 'SUCCESS') {
                        this.dataArr = data.text
                    }
                },
                error: function (XMLHttpRequest) {
                }
            });
        },
        // 跳转详细页面
        toDetailPage: function (url) {
            window.open(url);
        }
    }
};